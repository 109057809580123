<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs" :isShowBack="false"></ABreadcrumb>
    <!-- EOC -->
    <AError
      v-if="userApi.isError"
      :api="userApi"
      :callbackReset="() => (userApi.isError = false)"
    ></AError>
    <v-card class="mx-auto pa-3 mt-3" max-width="800" outlined min-height="200">
      <div class="d-flex justify-space-between mb-3">
        <div class="text-h6">My Family</div>
        <div v-if="users.length < 10 && auth.User.type == 'adult'">
          <v-btn
            width="150px"
            rounded
            color="primary"
            :to="{ name: 'PageFamilyUserAdd' }"
            dark
          >
            + New
          </v-btn>
        </div>
      </div>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <div class="text-left">{{ users.length }}/10 Members</div>
          <ALoader :isLoading="userApi.isLoading"></ALoader>
          <v-list>
            <v-list-item v-if="users.length == 0 && !userApi.isLoading">
              <v-list-item-content>
                <v-list-item-title class="text-center"
                  >No Items.</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="(user, i) in users" :key="i" class="menu-item">
              <v-list-item-avatar class="pr-6">
                <AAvatar
                  v-if="getAvatar(user)"
                  :width="40"
                  :height="40"
                  :avatar="getAvatar(user).avatar"
                />
                <v-img
                  v-else
                  :max-height="40"
                  :max-width="40"
                  :src="require('@/assets/CS0040_default_F0.png')"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  class="text-left"
                  v-text="user.name"
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                
                <v-menu
                  bottom
                  origin="center center"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    class="mr-3"
                      color="orange darken-3"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      Share
                    </v-btn>
                  </template>

                  <v-list width="180px">
                    <v-list-item>
                      <v-list-item-action class="text-center">
                        <ShareNetwork
                          class="text-decoration-none"
                          network="whatsapp"
                          title="Game Invitation"
                          :url="gameUrl"
                        >
                          <v-btn width="150px" rounded color="green" dark>
                            <v-icon left dark> mdi-whatsapp </v-icon>
                            Whatsapp
                          </v-btn>
                        </ShareNetwork>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action
                        ><v-btn
                          width="150px"
                          :disabled="copied"
                          @click="copy"
                          rounded
                          color="primary"
                        >
                          <v-icon left dark> mdi-content-copy </v-icon>
                          <span v-if="copied">Copied</span>
                          <span v-else>Copy</span>
                        </v-btn></v-list-item-action
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
            </v-list-item-action>
              <v-list-item-action>
                <v-btn :href="gameUrl" target="_blank" small color="primary"
                  >Play</v-btn
                ></v-list-item-action
              >
              <v-list-item-action v-if="auth.User.type == 'adult'">
                <v-btn
                  :to="{
                    name: 'PageFamilyUserRead',
                    params: { id: user.id },
                  }"
                  small
                  color="primary"
                  >View</v-btn
                ></v-list-item-action
              >
            </v-list-item>
            <v-list-item
              v-if="users.length < 10 && auth.User.type == 'adult'"
              :to="{ name: 'PageFamilyUserAdd' }"
              class="add-menu-item"
            >
              <v-list-item-content>
                <v-list-item-title class="text-center"
                  >+ Add your family member</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="auth.User.type == 'child'"
              :to="{ name: 'PageFamilyOwnerAdd' }"
              class="add-menu-item"
            >
              <v-list-item-content>
                <v-list-item-title class="text-center"
                  >+ Add Parent / Guardian</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
      
      <script>
import { mapState } from "vuex";
import VueSocialSharing from "vue-social-sharing";
import Vue from "vue";
Vue.use(VueSocialSharing);
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    copied: false,
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    step: 1,
    gameUrl: null,
    name: null,
    users:[],
    //BOC:[api]
    userApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    playerApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    players: [],
  }),
  created() {
     //BOC:[breadcrumbs]
     this.breadcrumbs.push({
      text: "Dashboard",
      to: { name: "PageFamilyDashboard" },
      exact: true,
    });
    //EOC
    this.userApi.method = "GET";
    this.userApi.url =
      this.$api.servers.sso + "/api/v1/" + this.$_getLocale() + "/backend/user";

    this.userApi.callbackReset = () => {
      this.userApi.isLoading = true;
      this.userApi.isError = false;
    };

    this.userApi.callbackError = (e) => {
      this.userApi.isLoading = false;
      this.userApi.isError = true;
      this.userApi.error = e;
    };

    this.userApi.callbackSuccess = (resp) => {
      this.userApi.isLoading = false;
      this.users = resp;
      this.$api.fetch(this.playerApi);
    };

    this.playerApi.method = "GET";
    this.playerApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/backend/account/" +
      this.auth.Account.id +
      "/player";

    this.playerApi.callbackReset = () => {
      this.playerApi.isLoading = true;
      this.playerApi.isError = false;
    };

    this.playerApi.callbackError = (e) => {
      this.playerApi.isLoading = false;
      this.playerApi.isError = true;
      this.playerApi.error = e;
    };

    this.playerApi.callbackSuccess = (resp) => {
      this.playerApi.isLoading = false;
      this.players = resp;
    };
    this.group = this.$_.find(this.auth.Groups, { type: "family" });
    this.gameUrl = `${process.env.VUE_APP_URL_WEB_GAME}/en/access?code=${this.group.code}`;
  },
  mounted() {
    this.$api.fetch(this.userApi);
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.gameUrl);
      this.copied = true;
      var self = this;
      setTimeout(() => {
        if (self && self.copied) self.copied = false;
      }, 800);
    },
    getAvatar(user) {
      let avatar = null;
      avatar = this.$_.find(this.players, function (n) {
        if (n.userId == user.id) {
          return n.avatar;
        }
      });
      return avatar;
    },
    clickMenu(item) {
      this.$router.push({ name: item.route, params: { id: item.id } });
    },
  },
};
</script>
  <style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.add-menu-item {
  border: 1px dashed black;
  margin: 3px;
}
.form-content {
  width: 600px !important;
}
</style>